import React, { useEffect, useState, useRef } from "react";
// import Slider from "react-slick";
import { Row, Col, Container, Modal } from "react-bootstrap";
import {
  HeadingDesc,
  HeadingDescSmall,
  HeadingDescVsmall,
} from "../../uiComponents/Headings";
import { DatePicker } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TableComponent } from "../../uiComponents/tableComponent";
import bulletGreen from "../../assets/images/svg/bulletgreen.svg";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getDashboardDetails } from "./redux/thunk";
import { useAppDispatch, useAppSelector } from "./../../redux/hooks";
import { selectDashboard } from "./redux/slice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./_dashboard.scss";
import SimpleSlider from "../../uiComponents/sliders/simpleSlider/SimpleSlider";
import moment from "moment";
import swal from "sweetalert";
import StarRating from "../../uiComponents/rating/ratingStars/StarRating";
import loadingGif from "../../assets/images/gif/loader_gif.gif";
import SimpleInstantSlider from "../../uiComponents/sliders/simpleSlider/SimpleInstantSlider";
import StickyTab from "../../uiComponents/stickyTab/StickyTab";
import { AiTwotoneLike } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Switch, Modal as AntModal, Checkbox } from "antd";
import { getCurrentUserData } from "../../utils/powerFuntions";
import axios from "axios";
import { FiChevronRight } from "react-icons/fi";
import classNames from "classnames";
import notificationSound from "../../assets/audio/appointment-notification.mp3";
import circleCheck from "../../assets/images/png/circle-check.png";
import { isEmpty } from "../../helpers/objectHelper";
import Loader from "../../uiComponents/loader/Loader";
import FriendlyPolite from "../../assets/images/png/friendly-polite.png";
import disableConsultation from "../../assets/images/svg/instant-disable-modal.svg";
import start from "../../assets/images/svg/start.svg";
import AgoraRTM from "agora-rtm-sdk";
import API from "../../utils/customAxios";
import { Accordion } from "react-bootstrap";
import getAppointmentTypeText from "../../utils/appointmentTypes";
import { getUserId } from "../../helpers/authHelper";
import Cookies from "js-cookie";
import DashboardNew from "../dashboardNew/DashboardNew";
import patientIcon from "../../assets/images/png/patient_icon.png";
import dateIcon from "../../assets/images/png/date_icon.png";
import timeIcon from "../../assets/images/png/time_icon.png";
import appointmentTypeIcon from "../../assets/images/png/appointment-type_icon.png";
import attachmentIcon from "../../assets/images/png/attachment-icon.png";
import mailIcon from "../../assets/images/png/mail_icon.png";
import whatsappIcon from "../../assets/images/png/whatsapp_icon.png";
import { generateDobArray, generateDobYears, isAlphabetsOnly, isDigits } from "../../utils/helperFunctions";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
function Dashboard() {
  const dispatch = useAppDispatch();
  const selectDashboardDetails = useAppSelector(selectDashboard);
  const checkboxRef = useRef();
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [summary, setSummary] = useState([]);
  const [review, setReview] = useState({});
  const [bodyData, setBodyData] = useState();
  const [badge, setBadge] = useState("");
  const [todayDate, setDate] = useState("");
  const [datefilter, setDateFilter] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [appointmentsCard, setAppointmentsCard] = useState({});
  const [instantData, setInstantData] = useState();
  const [upcomingApp, setUpcomingApp] = useState([]);
  const [showDashboard, setShowDashboard] = useState(true);
  const [showReviews, setShowReviews] = useState(false);
  const [pendingAppointments, setPendingAppointments] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);
  const [profileVerificationDoctor, setProfileVerificationDoctor] = useState(null);
  const dateFormatList = ['DD/MM/YYYY'];
  const [doctorData, setDoctorData] = useState({});
  const [isOnline, setIsOnline] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [temrsCheckbox, setTemrsCheckbox] = useState();
  const [instantDiscountData, setInstantDiscountData] = useState({});
  const [showInstantConsultations, setShowInstantConsultations] = useState(true);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showAppointmentSuccessModal, setShowAppointmentSuccessModal] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [genderValue, setGenderValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [networkValue, setNetworkValue] = useState('zong');
  const [dayValue, setDayValue] = useState('');
  const [monthValue, setMonthValue] = useState('');
  const [yearValue, setYearValue] = useState('');
  const [weightValue, setWeightValue] = useState('30');
  const [heightValue, setHeightValue] = useState('20');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [genderError, setGenderError] = useState('');
  const [heightError, setHeightError] = useState('');
  const [weightError, setWeightError] = useState('');
  const [dateError, setDateError] = useState('');
  const [timeError, setTimeError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [show1, setShow1] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [lobbyPatient, setLobbyPatient] = useState({});
  const [updatedAppointments, setUpdatedAppointments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  console.log({ lobbyPatient })
  // state for appointment success

  const [externalLink, setExternalLink] = useState('');

  const [appointmentSuccessData, setAppointmentSuccessData] = useState({
    name: '',
    type: '',
    date: '',
    time: ''
  });

  const handleChangeTerms = (e) => {
    if (e.target.checked === true) {
      setTemrsCheckbox(true);
    } else {
      setTemrsCheckbox(false);
    }
  };

  useEffect(() => {
    const filtered = instantData?.filter(item =>
      item?.patients.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [instantData, searchQuery]);

  useEffect(() => {
    // setTimeout(() => {
    //   if (!localStorage.getItem('showPopup')) {
    //     setOpenPop(true)
    //   }
    // }, 3000);
    var is_modal_show = sessionStorage.getItem("alreadyShow");
    if (is_modal_show !== "alreadyshown") {
      setTimeout(() => {
        setOpenPop(true);
      }, 3000);
      sessionStorage.setItem("alreadyShow", "alreadyshown");
    } else {
      setOpenPop(false);
    }
  }, []);

  const [mobile, setMobile] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [toastSuccessAppointment, setToastSuccessAppointment] = useState(false);
  const [show, setShow] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [arrived, setArrived] = useState(false);
  const [doctorOn, setDoctorOn] = useState(false);

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState();
  const [selectedCreatedAppointment, setSelectedCreatedAppointment] = useState();
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [rtmToken, setRtmToken] = useState(false);
  const [showStartLoader, setShowStartLoader] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShow2 = () => setDoctorOn(true);
  const handlehide2 = () => setDoctorOn(false);


  useEffect(() => {
    validateForm();
  }, [nameValue, phoneValue, genderValue, dayValue, monthValue, yearValue, selectedDate, selectedTime, loadingSlots]);

  function validateForm() {
    const isValid =
      nameValue &&
      phoneValue &&
      genderValue &&
      dayValue &&
      monthValue &&
      yearValue &&
      selectedDate &&
      selectedTime &&
      !loadingSlots;

    setIsFormValid(isValid);
  }

  const handleCloseCreateAppointment = () => {
    setShowAppointmentModal(false);
    setPhoneValue('')
    setNameValue('')
    setMonthValue('')
    setYearValue('')
    setDayValue('')
    setNameError('')
    setGenderError('')
    setPhoneError('')
    setTimeError('')
    setDateError('')
    setSelectedTime('')
    setSelectedDate('')
    setSelectedCreatedAppointment('')
    setAvailableSlots(null)
  }
  const handleCloseAppointmentSuccess = () => {
    setShowAppointmentSuccessModal(false);
    setPhoneValue('')
    setSelectedTime('')
    setSelectedDate('')
    setSelectedCreatedAppointment('')
    setAvailableSlots(null)
  }

  const forReviews = () => {
    setShowDashboard(false);
    setShowReviews(true);
  };
  const handleOkay = () => {
    setShow(false);
    setCancel(true);
  };
  const handleOkayClose = () => setCancel(false);
  const handleArrived = () => setArrived(false);

  useEffect(() => {
    (async () => {
      const res = await getCurrentUserData();
      if (res) {
        setDoctorData(res);
      }

      if (res?.is_instant_consultation === true) {
        setIsOnline(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 600) {
        setMobile(true);
      }
    }
    dispatch(getDashboardDetails());
  }, []);

  function cancelToggler() {
    swal({
      title: "Are you sure?",
      text: "Clicking OK will cancel the appointment",
      icon: "warning",
      buttons: true,
    }).then((willCancel) => {
      if (willCancel) {
        swal("Your appointment has been cancelled", {
          icon: "success",
        });
      } else {
        return null;
      }
    });
  }

  const hideModalAndInstantOpen = () => {
    setIsOnline(true);
    setDoctorOn(false);
  };

  async function onCheckboxChange(e) {
    // console.log(e.target.checked, 'isOnliness');
    // setIsOnline(e.target.checked);

    if (!isOnline) {
      handleShow2();
    }

    const userId = getUserId();

    const options = {
      headers: { Authorization: Cookies.get("token") },
    };

    if (e === true) {
      const data = {
        is_instant_consultation: 1,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/doctor/instant-online-offline`,
        data,
        options
      );

      if (response?.data?.code === 200) {
        if (doctorOn) {
          hideModalAndInstantOpen();
        }
      } else {
        setIsOnline(false);
      }
    } else {
      const appointments = pendingAppointments;

      let instantAppointments = appointments.filter(
        (item) =>
          (item?.type === "instant-consultation" || item?.type === "Scheduled Video Call") && item?.progress === "pending"
      );

      if (instantAppointments?.length > 0) {
        // swal("Appointments Pending!", "Please complete your pending appointments", "error");
        setShowWarningModal(true);
        setIsOnline(isOnline);
      } else if (!instantAppointments?.length > 0) {
        // setShowWarningModal(true);
        toast.error("DoctorNow Disabled");
      }

      const data = {
        is_instant_consultation: 0,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/doctor/instant-online-offline`,
        data,
        options
      );

      if (response?.data?.code === 200) {
        setIsOnline(false);
      } else {
        setIsOnline(true);
      }
    }
  }

  async function emitAppointmentStart(e, record) {
    if (record.canStart) {
      if (showStartLoader) {
        return;
      }

      setShowStartLoader(true);
      e.preventDefault();
      // console.log(record, "record");

      let options = {
        uid: "",
        token: "",
      };

      const appID = process.env.REACT_APP_AGORA_TEST_APP_ID;
      const appointmentId = record?.id;

      let token;
      let channelName = `${appointmentId}`;
      const response = await API.get(`/generate-agora-rtm-token`);
      if (response?.data?.code === 200) {
        token = response?.data?.data?.token;

        // console.log({token});

        if (token) options.token = token;

        if (appID && channelName) {
          const client = AgoraRTM.createInstance(appID);

          // console.log(token, "token");
          // console.log(doctorData?.id, "docData")
          await client.login({ token, uid: `${doctorData?.id}` });
          // await client.login();

          let channel = client.createChannel(channelName);

          await channel.join();

          await channel
            .sendMessage({ text: "appointment-started" })
            .then(() => {
              setShowStartLoader(false);
              history.push({
                pathname: `/appointment/${record?.id}`,
                state: {
                  id: record?.id,
                  type: record?.type,
                  user_id: record?.user_id,
                  visit_count: record?.visit_count,
                },
              });
            });
        }
      }
    }
  }

  let header = [
    { title: "Patients", dataIndex: "patients" },
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => a.date.localeCompare(b.date),
        multiple: 3,
      },
    },
    {
      title: "Consultation Time",
      dataIndex: "time",
      sorter: {
        compare: (a, b) => a.time.localeCompare(b.time),
        multiple: 2,
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
        multiple: 1,
      },
      render(text, record) {
        // console.log(text);
        if (text != null) {
          return {
            props: {
              style: {
                color: text.toLowerCase().startsWith("in-")
                  ? "#ff8900"
                  : text.toLowerCase().startsWith("i")
                    ? "#EF6286"
                    : "#29BCC1",
              },
            },

            children: <div className="capitalize">{text}</div>,
          };
        }
      },
    },
    { title: "Visit Count", dataIndex: "visit_count" },
    {
      title: "Action",
      dataIndex: "consult",
      align: "center",
      render(text, record) {
        // console.log(text, record, 'textrecord');

        // console.log(record?.progress);
        return {
          children: (
            <div>
              {record?.progress === "completed" ? (
                <Link
                  className="consult_later"
                  to={{
                    pathname: `/past-consultation/${record?.key}`,
                    state: {
                      id: record.key,
                      type: record?.type,
                      user_id: record?.user_id,
                      visit_count: record?.visit_count,
                    },
                  }}
                >
                  <div className="inline_data btn btn-transparent">
                    <p className="completed">View Details</p>
                  </div>
                </Link>
              ) : record?.progress !== "completed" ? (
                <div className="inline-data text-center align-items-end">
                  <div>
                    <div className="d-flex mb-2">
                      <p className="fs-13">Status</p>
                      <div className="status-tag-action text-uppercase arrived-tag _pending-tag ms-1">
                        Arrived
                      </div>
                    </div>
                    <Link
                      className="consult_now"
                      to={{
                        pathname: `/appointment/${record?.key}`,
                        state: {
                          id: record.key,
                          type: record?.type,
                          user_id: record?.user_id,
                          visit_count: record?.visit_count,
                        },
                      }}
                    >
                      <div className="inline_data btn btn-theme ">
                        <>
                          <p className="text-white">START</p>
                        </>
                      </div>
                    </Link>
                  </div>
                  {record?.type !== "instant-consultation" && (
                    <div className="cancel_now ms-4">
                      {/* <div className="inline_data btn btn-transparent" style={{ cursor: 'pointer' }} onClick={cancelToggler}> */}
                      <div
                        className="inline_data btn btn-transparent"
                        style={{ cursor: "pointer" }}
                        onClick={handleShow}
                      >
                        <p>CANCEL</p>
                      </div>

                      {/* <RiArrowRightSLine className="arrow_grey" /> */}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  className="consult_later"
                  to={{
                    pathname: `/appointment/${record?.key}`,
                    state: {
                      id: record.key,
                      type: record?.type,
                      user_id: record?.user_id,
                      visit_count: record?.visit_count,
                    },
                  }}
                >
                  <div className="inline_data">
                    <p>Appointment incomplete</p>
                    <RiArrowRightSLine className="arrow_grey" />
                  </div>
                </Link>
              )}
            </div>
          ),
        };
      },
    },
  ];




  // let instantHeader = [
  //   { title: "Patient Name", dataIndex: "patients" },
  //   {
  //     title: "Date  ",
  //     dataIndex: "date",
  //     // sorter: {
  //     //   compare: (a, b) => a.date.localeCompare(b.date),
  //     //   multiple: 3,
  //     // },
  //   },
  //   {
  //     title: "Consultation Time",
  //     dataIndex: "time",
  //     // sorter: {
  //     //   compare: (a, b) => a.time.localeCompare(b.time),
  //     //   multiple: 2,
  //     // },
  //   },
  //   {
  //     title: "Type",
  //     dataIndex: "type",
  //     // sorter: {
  //     //   compare: (a, b) => a.type.localeCompare(b.type),
  //     //   multiple: 1,
  //     // },
  //     render(text, record) {
  //       // console.log(text);
  //       if (text != null) {
  //         return {
  //           props: {
  //             style: {
  //               color: text.toLowerCase().startsWith("in-")
  //                 ? "#ff8900"
  //                 : text.toLowerCase().startsWith("i")
  //                   ? "#EF6286"
  //                   : "#29BCC1",
  //             },
  //           },

  //           children: <div className="capitalize">{text}</div>,
  //         };
  //       }
  //     },
  //   },
  //   { title: "Visit Count", dataIndex: "visit_count" },
  //   {
  //     title: "Action",
  //     dataIndex: "consult",
  //     align: "center",
  //     render(text, record) {
  //       // console.log(record?.progress);
  //       // console.log({record})
  //       return {
  //         children: (
  //           <div>
  //             {(record?.type === "instant-consultation" || "Scheduled Video Call") && (
  //               <div>
  //                 {/* <div className="d-flex mb-2">
  //                     <p className='fs-13'>Status</p>
  //                     <div className="status-tag-action text-uppercase arrived-tag _pending-tag ms-1">
  //                         Arrived
  //                     </div>
  //                   </div> */}
  //                 <div className="box_table_call">
  //                   {/* <p className="st01">Status <span className="status bg-green">ARRIVED</span></p> */}
  //                   <div className="d-flex  ">
  //                     <Link
  //                       onClick={(e) =>
  //                         emitAppointmentStart(e, {
  //                           id: record?.key,
  //                           type: record?.type,
  //                           user_id: record?.user_id,
  //                           visit_count: record?.visit_count,
  //                           canStart: record?.canStart,
  //                         })
  //                       }
  //                       className={classNames(
  //                         "w-100 consult_now consult_now_instant justify-content-start",
  //                         {
  //                           "disable-now-btn": 
  //                             (record?.type === "Scheduled Video Call" && (record?.canStart === false || showStartLoader === true || record?.lobby === false)) ||
  //                             (record?.type === "instant-consultation" && (record?.canStart === false || showStartLoader === true)),
  //                         }
  //                       )}
  //                       to={{
  //                         pathname:
  //                           record?.canStart === true && record?.lobby === true
  //                             ? `/appointment/${record?.key}`
  //                             : "/#",
  //                         state: {
  //                           id: record.key,
  //                           type: record?.type,
  //                           user_id: record?.user_id,
  //                           visit_count: record?.visit_count,
  //                         },
  //                       }}
  //                     >
  //                       <div
  //                         className="w-100 inline_data btn btn-theme justify-content-center"
  //                         style={{ minWidth: "100%", maxWidth: "100%" }}
  //                       >
  //                         <p className="text-white">START</p>
  //                       </div>
  //                     </Link>
  //                     {/* <Link className="btn btn-cancil01">CANCEL</Link> */}
  //                   </div>
  //                 </div>
  //               </div>
  //             )}
  //           </div>
  //         ),
  //       };
  //     },
  //   },
  // ];

  const instantHeader = [
    { title: "Patient Name", dataIndex: "patients" },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Consultation Time",
      dataIndex: "time",
    },
    {
      title: "Type",
      dataIndex: "type",
      render(text) {
        if (text != null) {
          return {
            props: {
              style: {
                color: text.toLowerCase().startsWith("in-")
                  ? "#ff8900"
                  : text.toLowerCase().startsWith("i")
                    ? "#EF6286"
                    : "#29BCC1",
              },
            },
            children: <div className="capitalize">{text}</div>,
          };
        }
      },
    },
    { title: "Visit Count", dataIndex: "visit_count" },
    {
      title: "Action",
      dataIndex: "consult",
      align: "center",
      render(text, record) {
        const canStart =
          (record?.type === "Scheduled Video Call" && record?.canStart && !showStartLoader && record?.lobby) ||
          (record?.type === "instant-consultation" && record?.canStart && !showStartLoader);

        return {
          children: (
            <div>
              {(record?.type === "instant-consultation" || record?.type === "Scheduled Video Call") && (
                <div className="box_table_call">
                  {console.log(record, "record")}
                  <div className="d-flex">
                    <Link
                      onClick={(e) =>
                        emitAppointmentStart(e, {
                          id: record?.key,
                          type: record?.type,
                          user_id: record?.user_id,
                          visit_count: record?.visit_count,
                          canStart: record?.canStart,
                        })
                      }
                      className={classNames(
                        "w-100 consult_now consult_now_instant justify-content-start",
                        { "disable-now-btn": !canStart }
                      )}
                      to={{
                        pathname: canStart ? `/appointment/${record?.key}` : "/#",
                        state: {
                          id: record.key,
                          type: record?.type,
                          user_id: record?.user_id,
                          visit_count: record?.visit_count,
                        },
                      }}
                    >
                      <div
                        className="w-100 inline_data btn btn-theme justify-content-center"
                        style={{ minWidth: "100%", maxWidth: "100%" }}
                      >
                        <p className="text-white">START</p>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ),
        };
      },
    },
  ];

  useEffect(() => {
    setUpdatedAppointments(instantHeader);
  }, [instantData, showStartLoader]);

  console.log({ updatedAppointments })
  const onChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  useEffect(() => {
    if (selectDashboardDetails?.data) {
      // console.log("exec");
      setReviews(selectDashboardDetails?.data?.reviews?.reviews);
      let appointments_card = selectDashboardDetails?.data?.appointments_count;
      // console.log(appointments_card, 'appointments_card');

      setAppointmentsCard(appointments_card);

      let d = new Date();
      d = d.toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const quickSummary = [
        {
          title: "Appointments",
          date: d,
          number: appointments_card.appointment_today_total,
        },
        {
          title: "Clinic visits for today",
          date: "Till Date",
          number: appointments_card.physical_appointment,
        },
        {
          title: "Video consultations for today",
          date: d,
          number: appointments_card.virtual_appointment,
        },
        {
          title: "Total Earnings",
          date: d,
          number: `Rs.${appointments_card.total_earning}`,
        },
        {
          title: "Total Receivables",
          subtitle: "You will receive your earnings every 15 days",
          date: d,
          number: `Rs.${appointments_card.total_receivables}`,
        },
        {
          title: (
            <div>
              DoctorNow <span>(Instant video calls)</span>
            </div>
          ),
          date: d,
          number: appointments_card.cancelled_by_doctor,
        },
      ];
      setSummary(quickSummary);

      let doctor_review = {
        average: selectDashboardDetails?.data?.review_avg,
        total: selectDashboardDetails?.data?.review_count,
      };
      setReview(doctor_review);
      let doc_badge = doctorData?.badge;

      let app_table = [];

      console.log(pendingAppointments, "pendingAppointments")
      if (pendingAppointments.length > 0) {
        let appointments_table = pendingAppointments;
        // console.log({appointments_table})

        let upcoming = appointments_table?.filter(
          (app) => app?.progress === "pending"
        );
        let upcom_app = [];

        if (upcoming?.length > 0) {
          const start = upcoming?.[0]?.appointment_time?.split(":");
          var timee = new Date(0, 0, 0, start?.[0], start?.[1], 0);
          const ap_date = upcoming?.[0]?.date?.split("-");
          var date = new Date(ap_date?.[0], ap_date?.[1] - 1, ap_date?.[2]);
          let appointment_date = date?.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
          });
          let appointment_time = timee?.toLocaleString("en-US", {
            hour: "numeric",
            hour12: true,
            minute: "numeric",
          });

          upcom_app.push(upcoming?.[0]);
          upcom_app.push(appointment_time);
          upcom_app.push(appointment_date);
          setUpcomingApp(upcom_app);
        }

        appointments_table?.map((data, index) => {
          // console.log(data, 'zain')
          try {
            const start = data?.appointment_time?.split(":");
            var timee = new Date(0, 0, 0, start?.[0], start?.[1], 0);
            const ap_date = data?.appointment_date?.split("-");
            var date = new Date(ap_date?.[0], ap_date?.[1] - 1, ap_date?.[2]);
            console.log(data, "datadata")
            let appointment_date = date?.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
            });
            // let appointment_time =  timee?.toLocaleString("en-US", {
            //   hour: "numeric",
            //   hour12: true,
            //   minute: "numeric",
            // });
            // ? moment(data?.schedule_date).format("LT") : ''
            let progress = data?.progress;
            let myobj = {
              key: data?.id,
              user_id: data?.user_id,
              patients: data?.patient_name
                ? data?.patient_name
                : data?.user?.name,
              // date: moment(data?.created_at)?.format("DD/MM/YY"),
              date: data?.schedule_date ? data?.schedule_date.slice(9, 19) : '',
              // time: moment(appointment_date)?.format("LT"),
              time: data?.schedule_date ? data?.schedule_date.slice(0, 8) : '',
              type: data?.appt_type ? data?.appt_type : data?.type,
              fees: data?.consultation_fee,
              lobby: data?.is_in_lobby,
              visit_count: data?.user?.appointment_count?.toString(),
              consult: data?.seconds_left <= 0,
              progress,
            };
            setDate(moment(data?.created_at).format("DD/MM/YY"));
            app_table.push(myobj);
          } catch (error) {
            let appointment_date = "";
            let appointment_time = "";
            let myobj = {
              key: data?.id,
              patients: data?.user?.name,
              date: data?.schedule_date ? data?.schedule_date.slice(9, 19) : '',
              time: data?.schedule_date ? data?.schedule_date.slice(0, 8) : '',
              type: data?.appt_type ? data?.appt_type : data?.type,
              fees: data?.consultation_fee,
              lobby: data?.is_in_lobby,
              visit_count: data?.user?.appointment_count?.toString(),
              consult: data?.seconds_left <= 0,
            };

            setDate(appointment_date);
            app_table.push(myobj);
          }
        });

        let double_table;
        double_table = app_table.filter(
          (data) => data?.type !== "instant-consultation"
        );

        const body_data = double_table;
        setBodyData(body_data);
        setBadge(doc_badge);

        let single_table;
        single_table = app_table.filter(
          (data) =>
            (data?.type === "instant-consultation" || data?.type === "Scheduled Video Call") &&
            data?.progress === "pending"
        );

        single_table?.sort((a, b) => a?.key - b?.key);
        single_table?.map((s, index) => {
          if (index === 0 || doctorData?.is_created_appointment_allowed == 1) {
            s.canStart = true;
          } else {
            s.canStart = false;
          }
        });
        // console.log(single_table, 'single_table');
        setInstantData(single_table);
      }
    }
  }, [selectDashboardDetails, pendingAppointments]);


  // console.log({instantData});

  // console.log({instantData})

  useEffect(() => {
    addDays();
  }, []);

  useEffect(() => {
    setPendingLoading(true);
    API.get(`/doctor/pending-appointment`)
      .then((res) => {
        if (res?.data?.code === 200) {
          setPendingLoading(false);
          setPendingAppointments(res?.data?.data);
        } else {
          setPendingLoading(false);
        }
      })
      .catch((err) => {
        setPendingLoading(false);
      });
  }, []);

  useEffect(() => {
    let quickSummaryContainer = document.querySelector(
      ".quickSummaryContainer"
    );
    quickSummaryContainer?.click();
    let audio = new Audio(notificationSound);

    const interval = setInterval(() => {
      API.get(`/doctor/pending-appointment`)
        .then((res) => {
          if (res?.data?.code === 200) {
            if (res?.data?.data?.length > 0) {
              audio.play();
            }
            setPendingAppointments(res?.data?.data);
          }
        })
        .catch((err) => { });

      // if (instantData?.length > 0) {
      //   audio.play();
      // }
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const padStart = (val) => {
    return val < 10 ? "0" + val : val;
  };
  const addDays = () => {
    const date = new Date();
    let datesCollection = [];

    for (var i = 0; i < 60; i++) {
      const newDate = new Date(date.getTime() + i * 1000 * 60 * 60 * 24);
      datesCollection.push(
        `${newDate.getFullYear()}/${padStart(
          newDate.getMonth() + 1
        )}/${padStart(newDate.getDate())}/`
      );
    }
    setDateFilter(datesCollection);
  };

  const formatedDate = datefilter?.map((item, index) => {
    if (index === 0) {
      return (
        moment(new Date(item)).format("ddd") +
        ", " +
        moment(new Date(item)).format("MMM D")
      );
    } else {
      return (
        moment(new Date(item)).format("ddd") +
        ", " +
        moment(new Date(item)).format("MMM D")
      );
    }
  });
  // // console.log(formatedDate)
  const buttons = datefilter?.map((index) => {
    // const { btnText, link } = item;
    // return <SimpleButton text={item} key={index} />;

    return (
      <TableComponent
        key={index}
        header={header}
        data={bodyData}
        pagination={false}
        onChange={onChange}
      />
    );
  });

  // console.log(review.average, "review");

  function updateTableData() {
    // let appointments_table = selectDashboardDetails?.data?.appointment_listing;
    let appointments_table = pendingAppointments;
    let app_table = [];
    appointments_table?.map((data, index) => {
      // console.log("app-type", data?.type)

      try {
        const start = data?.appointment_time?.split(":");
        var timee = new Date(0, 0, 0, start?.[0], start?.[1], 0);
        const ap_date = data?.appointment_date?.split("-");
        var date = new Date(ap_date?.[0], ap_date?.[1] - 1, ap_date?.[2]);
        let appointment_date = date?.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
        });
        let appointment_time = timee?.toLocaleString("en-US", {
          hour: "numeric",
          hour12: true,
          minute: "numeric",
        });
        let myobj = {
          key: data?.id,
          user_id: data?.user_id,
          patients: data?.patient_name,
          date: appointment_date,
          time: appointment_time,
          type: data?.type,
          fees: data?.consultation_fee,
          visit_count: data?.user?.appointment_count?.toString(),
        };
        setDate(appointment_date);
        app_table.push(myobj);
      } catch (error) {
        let appointment_date = "";
        let appointment_time = "";
        let myobj = {
          key: data?.id,
          patients: data?.user?.name,
          date: appointment_date,
          time: appointment_time,
          type: data?.type,
          fees: data?.consultation_fee,
          visit_count: data?.user?.appointment_count?.toString(),
        };
        setDate(appointment_date);
        app_table.push(myobj);
      }
    });

    return app_table;
  }

  function onTypeFilterClick(e) {
    // console.log(e?.key);

    let datee = [];
    formatedDate?.map((d) => datee.push(d?.split(", ")[1]));

    // console.log(datee, 'datee');

    if (e?.key == "3") {
      // window.location.reload();

      let app_table = updateTableData();

      setBodyData(app_table);
    } else if (Array.isArray(bodyData)) {
      let app_table = updateTableData();
      setBodyData(app_table?.filter((d) => d?.type?.toLowerCase() == e?.key));

      // // setBodyData(app_table);

      // setBodyData(bodyData?.filter((d) => d?.type?.toLowerCase() == e?.key));
    }
  }

  async function refreshTableData(e) {
    e.target.classList.add("pi-spin");
    await dispatch(getDashboardDetails()).then((data) => {
      // console.log(data);
      if (data) {
        e.target.classList.remove("pi-spin");
      }
    });
  }

  const menuList = [
    {
      link: "#overview",
      name: "Overview",
    },
    {
      link: "#badge",
      name: "Badge",
    },
    {
      link: "#reviews",
      name: "Your Reviews",
    },
  ];

  function submitCancelFeedback() {
    setCancel(false);
  }

  function navigateToReviews() {
    history.push("/reviews");
  }

  useEffect(() => {
    (async () => {
      const res = await API.get(`/doctor/earning-break-down`);
      if (res) {
        setInstantDiscountData(res?.data?.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await API.get(`/doctor/appointment/is-lobby`);
      if (res) {
        setLobbyPatient(res?.data?.data)
      }
    })();
  }, [pendingAppointments]);


  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(`doctor/progress`);

        if (response?.data?.code === 200) {
          setProfileVerificationDoctor(
            response?.data?.data?.profile_verification
          );
        }
      } catch (error) { }
    })();
  }, [profileVerificationDoctor]);
  console.log(moment(selectedTime, 'hh:mm A').format('HH:mm'), 'popisjac')
  async function copyTextToClipboard(e, text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1
  const todayDateAppointment = new Date().getDate();
  async function createAppointmentHandler(e) {
    e.preventDefault();

    setNameError('')
    setPhoneError('');
    setGenderError('');
    setDateError('')
    setTimeError('')
    setDateOfBirthError('');
    setHeightError('');
    setWeightError('');
    setGeneralError('');

    // const {name, phone, day, month, year, gender, network, height, weight} = createAppointmentData;

    let name = nameValue;
    let phone = phoneValue;
    let day = dayValue;
    let month = monthValue;
    let year = yearValue;
    let gender = genderValue;
    let network = networkValue;
    let height = heightValue;
    let weight = weightValue;
    let doctor_slot = selectedCreatedAppointment;
    let time = moment(selectedTime, 'hh:mm A').format('HH:mm');
    let date = selectedDate;
    let currDate = moment().format().substring(0, 10);

    let dateSplit = currDate.split("-");

    if (!name) {
      setNameError('Name is Required')
    }

    else if (!isAlphabetsOnly(name)) {
      setNameError("Name is invalid");
    }

    else if (phone.trim().length <= 0) {
      setPhoneError('Phone is required');
    }

    else if (!isDigits(phone) || phone.trim().length !== 11) {
      setPhoneError("Phone is invalid");
    }

    else if (!day || !month || !year) {
      setDateOfBirthError("Date of Birth is invalid");
    }

    else if (!gender) {
      setGenderError("Gender is required");
    }

    else if (!height) {
      setHeightError("Height is required");
    }

    else if (!weight) {
      setWeightError("Weight is requied");
    }
    else if (!date) {
      setDateError("date is requied");
    }
    else if (!time) {
      setTimeError("time is requied");
    }

    else if (year > currentYear ||
      (year === currentYear && month > currentMonth) ||
      (year === currentYear && month === currentMonth && day > todayDate)) {
      setDateOfBirthError("Date is invalid");
    }

    else {
      let payload = {
        name,
        phone,
        network,
        date_of_birth: `${day}-${month}-${year}`,
        gender,
        height,
        weight,
        doctor_slot,
        date,
        time
      };

      try {
        const response = await API.post("/doctor/create-instant-appointment", payload);

        if (response?.data?.code === 200) {
          const siteUrl = process.env.REACT_APP_FOOTER_BASE_URL;
          const appointmentId = response?.data?.data?.appoitment?.id;
          const token = response?.data?.data?.hash;

          setExternalLink(`${siteUrl}/dynamic-appointment/${appointmentId}?token=${token}`);

          let type;

          if (response?.data?.data?.appoitment?.type === 'instant-consultation') {
            type = "Instant Consultation"
          }

          setAppointmentSuccessData({
            name: response?.data?.data?.appoitment?.patient_name,
            type,
            date: response?.data?.data?.appoitment?.date,
            time: response?.data?.data?.appoitment?.time
          })

          setShowAppointmentModal(false);
          setShowAppointmentSuccessModal(true);
          setToastSuccessAppointment(true);
          setTimeout(() => {
            setToastSuccessAppointment(false);
          }, 3000);

        }

        else {
          toast.error(response?.data?.message);
        }

      } catch (error) {
        console.error(error);
        setGeneralError(error?.response?.data?.message || error?.message);
        toast.error(error?.response?.data?.message || error?.message);
      }
    }


  }

  function nameHandler(e) {
    setNameValue(e.target.value);
  }

  function phoneHandler(e) {
    const inputValue = e.target.value.slice(0, 11); // Limit input to 11 characters
    setPhoneValue(inputValue);
    if (/^\d{0,11}$/.test(inputValue)) {
      console.log(phoneValue, "setPhoneValue")
      setPhoneError('');
    } else {
      setPhoneError('Phone must be exactly 11 digits');
    }
  }

  // const createAppointmentDate = async (date) => {
  //   setSelectedDate(date);
  //   // if(selectedDate){
  //     try {
  //     const response = await API.get(`/doctor-slots?date=${moment(selectedDate)?.format('DD-MM-YYYY')}`);
  //       if(response?.status == 200) {
  //         setAvailableSlots(response?.data?.data?.[0]?.clinic_timings)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   // }
  // }

  const createAppointmentDate = async (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (selectedDate) {
      const fetchAvailableSlots = async () => {
        setLoadingSlots(true);
        try {
          const response = await API.get(`/doctor-slots?date=${moment(selectedDate).format('DD-MM-YYYY')}`);
          if (response?.status === 200) {
            const slots = response?.data?.data;
            setAvailableSlots(slots);
            if (slots.length > 0) {
              const firstSlot = slots[0];
              setSelectedTime(firstSlot.start_time);
              setSelectedCreatedAppointment(firstSlot.id);
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingSlots(false);
        }
      };

      fetchAvailableSlots();
    }
  }, [selectedDate]);



  return (
    <>
      {isEmpty(doctorData) || pendingLoading || showStartLoader ? (
        <Loader />
      ) : (
        <section className="dashboard bgLinghtGray dash_wrapper cover_space">
          {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

          {profileVerificationDoctor === 0 ? (
            <>
              <DashboardNew />
            </>
          ) : (
            <>
              {summary?.length === 0 ? (
                <div className="loaderWrapper">
                  <img src={loadingGif} alt="" />
                </div>
              ) : (
                <>
                  {mobile && <StickyTab menuList={menuList} type="a" />}
                  {showDashboard && (
                    <>
                      <Container fluid>
                        {!mobile && (
                          <Row>
                            <Col lg={12}>
                              {/* <HeadingWithSpaceLarge text="DASHBOARD" /> */}
                            </Col>
                          </Row>
                        )}
                        <Row className="align-items-center mt-3">
                          {doctorData?.is_created_appointment_allowed == 1 && lobbyPatient && (
                            <>
                              <Col lg={12}>
                                <div className="boxPaitentBlue">
                                  <Row className="align-items-center">
                                    <Col lg={6}>
                                      <div className="d-flex align-items-center"><span className="infoIcon"></span><p> Patient {lobbyPatient?.message} is in the lobby</p></div>
                                    </Col>
                                    <Col lg={6} className="text-end">
                                      <Link
                                        onClick={(e) =>
                                          emitAppointmentStart(e, {
                                            id: lobbyPatient?.id,
                                            type: lobbyPatient?.type,
                                            user_id: lobbyPatient?.user_id,
                                            canStart: true,
                                          })
                                        }
                                        to={{
                                          pathname: `/appointment/${lobbyPatient?.id}`,
                                          state: {
                                            id: lobbyPatient.id,
                                            type: lobbyPatient?.type,
                                            user_id: lobbyPatient?.user_id,
                                          },
                                        }}
                                      >
                                        <button> <img src={start} alt="start icon"></img>  START CONSULTATION</button>
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </>
                          )}
                          {toastSuccessAppointment && (
                            <div className="mt-2 linkCreatedSuccess d-flex justify-content-center">
                              <p>
                                Link created and sent successfully
                              </p>
                            </div>
                          )}
                          {doctorData && (
                            <Col lg={12}>
                              <div className="mt-3 card bg-white p-3 d-md-flex flex-row align-items-end justify-content-between d-block">
                                <div className="d-flex align-items-center">
                                  <div className="imgProfile me-3">
                                    <img
                                      src={doctorData?.image}
                                      alt=""
                                      className="img-fluid img-rounded-md"
                                    />
                                  </div>
                                  <div className="d-flex flex-md-row flex-column">
                                    <div className="nameDoctor order-2 order-md-1">
                                      <h4 className="color-313131">
                                        Hello, {doctorData?.prefix}.{" "}
                                        {doctorData?.name}!
                                      </h4>

                                      <div className="reviewsRatings d-md-flex align-items-center d-none">
                                        <AiTwotoneLike color="#3B9915" />
                                        <h6 className="mx-2 colorGreenLike">
                                          {" "}
                                          {
                                            selectDashboardDetails?.data
                                              ?.reviews?.average_rating
                                          }
                                          /5 (
                                          <span
                                            className="total-review-count"
                                            onClick={navigateToReviews}
                                          >
                                            {doctorData.review_count} REVIEWS
                                          </span>
                                          ){" "}
                                        </h6>
                                        <StarRating
                                          rating={
                                            selectDashboardDetails?.data
                                              ?.reviews?.average_rating
                                          }
                                        />
                                      </div>
                                    </div>
                                    {doctorData?.is_featured === true ? (
                                      <div class="status articleLabel d-flex align-items-center ms-md-2 ms-0 order-1 order-md-2">
                                        <FontAwesomeIcon icon="fa fa-check" />
                                        <p class="text ms-2">Featured Doctor</p>
                                      </div>
                                    ) : doctorData?.badge ? (
                                      <div
                                        class="status badge articleLabel d-flex align-items-center"
                                        style={{
                                          background: "#E9EAEF !important",
                                        }}
                                      >
                                        <img src={FriendlyPolite} />
                                        <p
                                          class="text ms-2"
                                          style={{
                                            color: "#263360 !important",
                                          }}
                                        >
                                          {doctorData?.badge}
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="reviewsRatings d-flex align-items-center d-md-none mt-3">
                                  <AiTwotoneLike color="#3B9915" />
                                  <h6 className="mx-2 colorGreenLike fontSetMobileReview">
                                    {" "}
                                    {
                                      selectDashboardDetails?.data?.reviews
                                        ?.average_rating
                                    }
                                    /5 (
                                    <span
                                      className="total-review-count"
                                      onClick={navigateToReviews}
                                    >
                                      {doctorData.review_count} REVIEWS
                                    </span>
                                    ){" "}
                                  </h6>
                                  <StarRating
                                    rating={
                                      selectDashboardDetails?.data?.reviews
                                        ?.average_rating
                                    }
                                  />
                                </div>
                                <div className="instant_consult hk_db_instant_toggle doctorNowSameLineMobile">
                                  <div className="flex_center gap">
                                    {/* <HeadingDescSmall text="DoctorNow (Instant video calls)" /> */}
                                    {doctorData?.is_created_appointment_allowed === 1 ? (
                                      <button onClick={(e) => setShowAppointmentModal(true)} className="create-appointment-btn">
                                        Create Appointment
                                      </button>
                                    ) : (
                                      <>
                                        <h4>
                                          DoctorNow
                                          <br />
                                          <span>(Instant video calls)</span>
                                        </h4>
                                        <label className="switch ms-2 toast_success">
                                          <Switch
                                            className="ms-2 is-online-switch show_toast_click"
                                            checked={isOnline}
                                            onChange={onCheckboxChange}
                                          />
                                        </label>
                                        <ToastContainer
                                          position="top-center"
                                          autoClose={500}
                                          hideProgressBar
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                          theme="dark"
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>

                        <Row>
                          <Col
                            lg={12}
                            className={
                              mobile
                                ? location?.search?.split("?")[1] ===
                                  "#overview" ||
                                  typeof location?.search?.split("?")[1] ===
                                  "undefined"
                                  ? "mt-4"
                                  : "d-none"
                                : "mt-4"
                            }
                            id="overview"
                          >
                            {mobile && (
                              <>
                                {/* <HeadingDesc text="Here's your summary for today!" />
                            {upcomingApp?.length > 0 && (
                              <div className="upcoming_appointment">
                                <div className="flex_center justify_between">
                                  <HeadingDescVsmall text="Upcoming Appointment" />
                                  <a>
                                    <div className="flex_start">
                                      <Link to="/appointments">
                                        <HeadingDescVsmall text="View All" />
                                      </Link>
                                      <RiArrowRightSLine />
                                    </div>
                                  </a>
                                </div>
                                <div className="single_upcoming">
                                  <div className="flex_start">
                                    <div className="upcoming_img">
                                      <img src={upcomingImg} alt="upcoming" />
                                    </div>
                                    <div className="upcoming_data">
                                      <HeadingDescSmall
                                        text={upcomingApp?.[0]?.user?.name}
                                      />
                                      <HeadingDescVsmall text="Gynecologist, Obstetrician" />
                                      <HeadingDescSmall
                                        text={upcomingApp?.[0]?.type}
                                        color={
                                          upcomingApp?.[0]?.type?.includes(
                                            "video"
                                          )
                                            ? "blue"
                                            : "pink"
                                        }
                                      />
                                      <HeadingDescVsmall
                                        text={
                                          upcomingApp?.[2] +
                                          " - " +
                                          upcomingApp?.[1]
                                        }
                                      />
                                      <CountdowmTimer text="Starts in 6 Hours 53 minutes" />
                                    </div>

                                  </div>
                                </div>
                              </div>
                            )} */}
                              </>
                            )}
                            <div className="quickSummaryContainer row">
                              {summary.map((summary, index) => {
                                const { title, date, number, subtitle } =
                                  summary;
                                return (
                                  <div
                                    key={index}
                                    className="quickSummaryBox col-md-4 cd"
                                  >
                                    <div className="card d-block">
                                      <div className="d-flex justify-content-between w-100">
                                        <HeadingDescSmall text={title} />
                                        {!mobile && (
                                          <div className="d-none d-md-block">
                                            <HeadingDescVsmall text={date} />
                                          </div>
                                        )}
                                      </div>
                                      <h4 className="subtitle sub_dash">
                                        {subtitle}
                                      </h4>

                                      <h3 className="d-flex justify-content-end">
                                        {number}
                                      </h3>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {mobile && (
                              <div className="instant_consult d-none">
                                <div className="flex_center gap">
                                  <HeadingDescSmall text="Instant Consultation" />
                                  <label className="switch ms-2">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Row className="d-md-none appointmentsTableMobile">
                          <Accordion>
                            {pendingAppointments &&
                              pendingAppointments?.length > 0 &&
                              pendingAppointments?.map((item, index) => (
                                <Accordion.Item className="mt-4" key={index}>
                                  <Accordion.Header>
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <h5 className="mobileAccordionDate">
                                        {item?.appointment_date}
                                      </h5>
                                      <p className="mobileAccordionTime">
                                        {item?.appointment_time}{" "}
                                      </p>
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Row>
                                      <div className="col-4 text-center">
                                        <h6 className="pNameHead">
                                          Patient Name
                                        </h6>
                                        <p className="pNameInner">
                                          {item?.patient_name}
                                        </p>
                                      </div>
                                      <div className="col-4 text-center px-0">
                                        <h6 className="pNameHead">
                                          Consultation Type
                                        </h6>
                                        <p className="pNameInner">
                                          {" "}
                                          {getAppointmentTypeText(
                                            item?.type
                                          )}{" "}
                                        </p>
                                      </div>
                                      <div className="col-4 text-center">
                                        <h6 className="pNameHead">
                                          Visit Count
                                        </h6>
                                        <p className="pNameInner">
                                          {" "}
                                          {item?.user?.appointment_count}{" "}
                                        </p>
                                      </div>
                                      <div className="col-4 text-center">
                                        <button
                                          disabled={index > 0 || doctorData?.is_created_appointment_allowed == 1 ? true : false}
                                          className="btn btn-thmeme-blue text-uppercase accordBtnMobile"
                                        >
                                          Start
                                        </button>
                                      </div>
                                      <div className="col-4 text-center d-none">
                                        <button className="btn btn-transparent text-uppercase accordBtnMobile">
                                          Cancel
                                        </button>
                                      </div>
                                      <div className="col-4 text-center d-none">
                                        <p className="statusHeadTable">
                                          Status
                                        </p>
                                        <div className="status-tag-action text-uppercase _arrived-tag pending-tag ms-1">
                                          Arrived
                                        </div>
                                      </div>
                                    </Row>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                          </Accordion>
                        </Row>
                        {doctorData?.is_created_appointment_allowed == 1 && (
                          <div className="row mt-3" >
                            {/* <Slider dots={true}>{renderSlides()}</Slider> */}
                            <Col lg="12" className="">
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control formSearch"
                                  placeholder="Search by patient name"
                                  value={searchQuery}
                                  onChange={e => setSearchQuery(e.target.value)}
                                />
                                <div className="daterange">
                                  <div className="date_filter d-flex align-items-center dateTimeCalen">
                                    <span className="from_01 earningsFontFamily">Date Range</span>
                                    <DatePicker.RangePicker
                                      value={[startDate, endDate]}

                                      clearIcon={null}
                                      format={dateFormatList}
                                    />

                                  </div>
                                </div>
                              </div>


                            </Col>
                          </div>
                        )}

                        {showInstantConsultations && (
                          <Row className="">
                            <div className="overflow_div">
                              <div className="mt-4 desktop_heading d-sm-block d-none">
                                <SimpleInstantSlider
                                  date={formatedDate}
                                  header={updatedAppointments}
                                  bodyData={filteredData}
                                  text="Your instant consultations"
                                  disableDateSlider={true}
                                  instant={true}
                                >
                                  {buttons}
                                </SimpleInstantSlider>
                              </div>
                            </div>
                          </Row>
                        )}
                        <Row
                          className={
                            mobile
                              ? location?.search?.split("?")[1] === "#reviews"
                                ? ""
                                : "d-none"
                              : ""
                          }
                          id="reviews"
                        >
                          {mobile ? (
                            <div className="reviews">
                              {reviews?.map((rev) => (
                                <div className="single_reviews">
                                  <div className="flex_center justify-content-between">
                                    <div className="column_flex">
                                      <HeadingDesc text={rev?.review_by_user} />
                                      <HeadingDescSmall
                                        text={rev?.appointment_type}
                                        color={
                                          rev?.appointment_type ==
                                            "instant-consultation"
                                            ? "blue"
                                            : "pink"
                                        }
                                      />
                                      <StarRating rating={rev?.total} />
                                    </div>
                                    <div className="right_arrow">
                                      <RiArrowRightSLine />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="overflow_div d-none">
                              <div className="mt-3">
                                <SimpleSlider
                                  date={formatedDate}
                                  header={header}
                                  bodyData={bodyData}
                                  onTypeFilterClick={onTypeFilterClick}
                                  onCheckboxChange={onCheckboxChange}
                                  refreshTableData={refreshTableData}
                                >
                                  {buttons}
                                </SimpleSlider>
                              </div>
                            </div>
                          )}
                        </Row>
                        {/*
                  <div>
                      <Reviews/>
                  </div> */}
                      </Container>



                      <AntModal
                        className="consultaionEndedModal disableModal"
                        title=""
                        centered
                        visible={showWarningModal}
                        closable={true}
                        onOk={() => {
                          setShowWarningModal(false);
                        }}
                        footer={[
                          <Button
                            className="col-md-9 m-auto"
                            key="info"
                            onClick={() => {
                              setShowWarningModal(false);
                            }}
                          >
                            Okay
                          </Button>,
                        ]}
                      >
                        <div className="col-md-11 m-auto text-center">
                          <button
                            onClick={() => {
                              setShowWarningModal(false);
                            }}
                            type="button"
                            aria-label="Close"
                            class="ant-modal-close"
                          >
                            <span class="ant-modal-close-x">
                              <span
                                role="img"
                                aria-label="close"
                                class="anticon anticon-close ant-modal-close-icon"
                              >
                                <svg
                                  viewBox="64 64 896 896"
                                  focusable="false"
                                  data-icon="close"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                              </span>
                            </span>
                          </button>
                          <img
                            src={disableConsultation}
                            alt=""
                            className="img-fluid mb-3"
                          />
                          <h5 className="ff-Nunito color-313131 fs-24 line-height-35 fw-500 mb-3">
                            DoctorNow disabled
                          </h5>
                          <p className="ff-circular fw-300 fs-17 line-height-24">
                            Your instant video calls will now be disabled.
                          </p>
                          <p className="ff-circular fw-300 fs-17 line-height-24">
                            Please attend to the patient currently waiting in
                            queue.
                          </p>
                        </div>
                      </AntModal>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/* Logout Modal */}

          <Modal
            className="text-center ToGetCenter cancel-appointment-modal"
            show={show}
            onHide={handleClose}
          >
            <Modal.Header
              className="border-bottom-0"
              closeButton
            ></Modal.Header>
            <Modal.Body className="pt-0">
              <h4 className="fw-600 ff-Nunito">
                Are you sure you want to cancel the appointment?
              </h4>
            </Modal.Body>
            <Modal.Footer className="border-top-0 row flex-md-nowrap justify-content-center pb-4">
              <Button
                className="btn-btn-lg btn-transparent modal-btn me-md-3"
                onClick={handleOkay}
              >
                Yes
              </Button>

              <Button
                className="btn btn-lg theme-bg modal-btn ms-md-3"
                onClick={handleClose}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Create Appointment Modal  */}

          <Modal
            className="ToGetCenter create-appointment-modal"
            show={showAppointmentModal}
            onHide={handleCloseCreateAppointment}
            onBackdropClick={() => { }}
          >
            <Modal.Header
              className="border-bottom-0"
              closeButton
            ></Modal.Header>
            <Modal.Body className="px-4 pt-0">
              <h4 className="create-appointment-heading">
                Create Appointment
              </h4>

              <p className="create-appointment-text mt-2">
                Please enter the patient details.
              </p>

              <form onSubmit={createAppointmentHandler}>
                <div className="form-area mt-4 fullForCreatedAppointment">
                  <div className="form-group">
                    <label htmlFor="phone">Phone Number*</label>
                    <input type="number" className="form-control inputCol" placeholder="Enter mobile number" value={phoneValue} name="phone" pattern="[0-9]{11}" maxLength={11} onChange={phoneHandler} />
                    {phoneError && (
                      <p className="text-danger"> {phoneError} </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Name*</label>
                    <input type="text" className="form-control inputCol" maxLength={30} placeholder="Enter full name" name="name" onChange={(e) => nameHandler(e)} />
                    {nameError && (
                      <p className="text-danger"> {nameError} </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="gender">Gender*</label>
                    <select value={genderValue} type="text" className={`form-control selectIcon ${genderValue == '' ? 'placeholderr' : 'actualColor'}`} placeholder="Select Gender" onChange={(e) => {
                      setGenderValue(e.target.value)
                    }} >
                      <option value="" disabled>Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>

                    {genderError && (
                      <p className="text-danger"> {genderError} </p>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="dob">Date Of Birth*</label>
                    <div className="d-flex justify-content-between gap15">
                      <select value={dayValue} className={`form-control selectIcon ${dayValue == '' ? 'placeholderr' : 'actualColor'}`} type="text" placeholder="Date" onChange={(e) => {
                        setDayValue(e.target.value)
                      }} >
                        <option value="" disabled>Date</option>
                        {generateDobArray("day").map((item, index) => (
                          <option key={index} value={item}> {item} </option>
                        ))}
                      </select>

                      <select value={monthValue} type="text" className={`form-control selectIcon ${monthValue == '' ? 'placeholderr' : 'actualColor'}`} placeholder="Month" onChange={(e) => {
                        setMonthValue(e.target.value)
                      }}>
                        <option value="" disabled>Month</option>
                        {generateDobArray("month").map((item, index) => (
                          <option key={index} value={item}> {item} </option>
                        ))}
                      </select>

                      <select value={yearValue} type="text" className={`form-control selectIcon ${yearValue == '' ? 'placeholderr' : 'actualColor'}`} placeholder="Year" onChange={(e) => {
                        setYearValue(e.target.value)
                      }}>
                        <option value="" disabled>Year</option>
                        {generateDobYears().map((item, index) => (
                          <option key={index} value={item}> {item} </option>
                        ))}
                      </select>
                    </div>
                    {dateOfBirthError && (
                      <p className="text-danger"> {dateOfBirthError} </p>
                    )}
                  </div>
                  <div className="form-group datePickerModal">
                    <Row>
                      <Col lg="6">
                        <label htmlFor="dob" className="mb-1">Date* </label>
                        <DatePicker
                          value={selectedDate}
                          placeholder="Select date"
                          onChange={createAppointmentDate}
                          className="form-control dateOfBirthDatePicker"
                          disabledDate={(current) => {
                            return moment().add(-1, 'days') >= current
                          }}
                        />
                        {dateError && (
                          <p className="text-danger"> {dateError} </p>
                        )}
                      </Col>
                      <Col lg="6" className="timePicker">
                        <label htmlFor="dob">Time* </label>
                        <select type="text" className={`form-control selectIcon ${selectedTime == '' ? 'placeholderr' : 'actualColor'}`} placeholder="Select time"
                          onChange={(e) => {
                            const selectedValue = JSON.parse(e.target.value);
                            setSelectedTime(selectedValue.start_time);
                            setSelectedCreatedAppointment(selectedValue.id);
                          }}
                        >
                          {/* <option  value="" disabled>Select time</option> */}
                          {availableSlots?.map((item) => (
                            <option key={item.id} value={JSON.stringify({ start_time: item.start_time, id: item.id })}>{item?.start_time}</option>
                          ))}
                        </select>
                        {timeError && (
                          <p className="text-danger timeErrrorPosition"> {timeError} </p>
                        )}
                      </Col>
                    </Row>
                  </div>

                </div>

                <div className="text-center d-flex justify-content-center mt-4 mb-2">
                  <button
                    style={{ height: '45px' }}
                    // onClick={(e) => writePrescription(e)}
                    className={`${!isFormValid ? 'submit-btn-completedDisabled' : 'submit-btn-completed'} add-record-btn text-uppercase w-100`}
                    type="submit"
                    disabled={!isFormValid}
                  >
                    Create
                    <span className={`${!isFormValid ? 'add-record-chevronDisabled' : 'add-record-chevron'}`}>
                      <FiChevronRight />
                    </span>
                  </button>
                </div>

              </form>
            </Modal.Body>
            {/* <Modal.Footer className="border-top-0 row flex-md-nowrap justify-content-center pb-4">
            
            </Modal.Footer> */}
          </Modal>

          {/* Create Appointment Success  */}

          <Modal
            className="ToGetCenter create-appointment-success"
            show={showAppointmentSuccessModal}
            onHide={handleCloseAppointmentSuccess}
            onBackdropClick={() => { }}
          >
            <Modal.Header
              className="border-bottom-0"
              closeButton
            ></Modal.Header>
            <Modal.Body className="px-4 pt-0">
              <div className="appointment-centered-header text-center">
                <img src={circleCheck} alt="" />
                <h4> Appointment Created</h4>
                <p>Your appointment has been created successfully</p>
              </div>

              <div className="appointment-receipt-success mt-4 py-3 px-2">
                <div className="appointment-receipt-row">
                  <div className="d-flex align-center">
                    <img src={patientIcon} className="mb-0 img-fluid" alt="icon" />
                    <h5 className="ml-2"> Patient</h5>
                  </div>
                  {appointmentSuccessData.name && (
                    <h6>{appointmentSuccessData.name}</h6>
                  )}

                </div>

                <div className="appointment-receipt-row">
                  <div className="d-flex align-center">
                    <img src={appointmentTypeIcon} className="mb-0 img-fluid" alt="icon" />
                    <h5 className="ml-2"> Appointment Type</h5>
                  </div>

                  <h6>{appointmentSuccessData.type}</h6>
                </div>

                <div className="appointment-receipt-row">
                  <div className="d-flex align-center">
                    <img src={dateIcon} className="mb-0 img-fluid" alt="icon" />
                    <h5 className="ml-2"> Date</h5>
                  </div>
                  <h6>{moment(appointmentSuccessData.date).format('DD MMM,YYYY')}</h6>
                </div>

                <div className="appointment-receipt-row">
                  <div className="d-flex align-items-center">
                    <img src={timeIcon} className="mb-0 img-fluid" alt="icon" />
                    <h5 className="ml-2"> Time</h5>
                  </div>
                  <h6>{moment(appointmentSuccessData.time, 'HH:mm').format('hh:mm A').toLowerCase()}</h6>
                </div>
              </div>

              <h4 className="text-right copy"> Copy Link</h4>

              <div className="copy-link-container">
                <img src={attachmentIcon} className="copy-link-icon mb-0 img-fluid" />
                <input type="text" className="w-100 copy-link-input ps-5" value={externalLink} />
              </div>

              <button
                style={{ height: '45px', minWidth: '100%' }}
                // onClick={(e) => writePrescription(e)}
                className=" mt-4 mb-4 submit-btn-completed text-center add-record-btn text-uppercase w-100"
                onClick={(e) => copyTextToClipboard(e, externalLink)}
              >
                Copy Link

              </button>

              <div className="share-container">
                <h4> Share the link via</h4>
                <WhatsappShareButton separator={`\n`} url={externalLink} crossOrigin="anonymous" className="whatsappIconsShare"> <img style={{ cursor: 'pointer' }} src={whatsappIcon} /> </WhatsappShareButton>
                <EmailShareButton url={externalLink} subject="Meri Sehat" separator={`\n`}> <img style={{ cursor: 'pointer' }} src={mailIcon} /> </EmailShareButton>

              </div>


            </Modal.Body>

          </Modal>

          {/* =======================================Confirm Cancel-================================ */}

          <Modal
            className="text-center ToGetCenter cancel-appointment-modal"
            show={cancel}
            onHide={handleOkayClose}
          >
            <Modal.Header
              className="border-bottom-0"
              closeButton
            ></Modal.Header>
            <div className="px-5 modal-cancel">
              <Modal.Body className="pt-0 px-0">
                <h4 className="fw-600 ff-Nunito">
                  Tell us why you want to cancel
                </h4>
                <textarea></textarea>
              </Modal.Body>
              <Modal.Footer className="border-top-0 row flex-md-nowrap justify-content-center pb-4">
                <button
                  type="submit"
                  onClick={submitCancelFeedback}
                  className="review-button text-uppercase modalOkay-phone-btn"
                >
                  submit
                  <span
                    className="modalOkay-phone-chevron"
                    style={{ height: "53px" }}
                  >
                    <FiChevronRight />
                  </span>
                </button>
              </Modal.Footer>
            </div>
          </Modal>
          {/* =======================================Confirm Cancel-================================ */}
          {/* =======================================mark patient-================================ */}

          <Modal
            className="text-center ToGetCenter cancel-appointment-modal"
            show={arrived}
            onHide={handleArrived}
          >
            <Modal.Header
              className="border-bottom-0"
              closeButton
            ></Modal.Header>
            <Modal.Body className="pt-0">
              <h4 className="fw-600 ff-Nunito">Mark patient as arrived?</h4>
            </Modal.Body>
            <Modal.Footer className="border-top-0 row flex-md-nowrap justify-content-center pb-4">
              <Button className="btn-btn-lg btn-transparent modal-btn me-md-3">
                No
              </Button>

              <Button className="btn btn-lg theme-bg modal-btn ms-md-3">
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          {/* =======================================mark patient-================================ */}

          {/* =======================================doctor now on modal-================================ */}

          <AntModal
            className="text-center ToGetCenter cancel-appointment-modal hk_instant_modal_open_ask_modal"
            title=""
            centered
            visible={doctorOn}
            onHide={handlehide2}
            onCancel={handlehide2}
          >
            <Modal.Body className="pt-0 px-4">
              <h4 className="fw-600 ff-Nunito  ">
                Are you sure you want
                <br /> to enable your instant video calls?
              </h4>
              <ul className="bulletPointsGreen">
                <li className="d-flex align-items-start">
                  <img
                    src={bulletGreen}
                    alt="Bullet Icon"
                    className="img-fluid me-2"
                  />
                  <span>
                    Instant video calls are limited to 10 minutes per call
                  </span>
                </li>
                <li className="d-flex align-items-start">
                  <img
                    src={bulletGreen}
                    alt="Bullet Icon"
                    className="img-fluid me-2"
                  />
                  <span>
                    {" "}
                    The consultation fee is inclusive of GST and<br></br> Meri
                    Sehat’s 20% commission
                  </span>
                </li>
                <li className="d-flex align-items-start">
                  <img
                    src={bulletGreen}
                    alt="Bullet Icon"
                    className="img-fluid me-2"
                  />
                  <span>You will receive 80% of the earnings per call</span>
                </li>
              </ul>
              <Accordion defaultActiveKey="1">
                <div>
                  <Accordion.Item className="border-0" eventKey="0">
                    <Accordion.Header className="modalHeaderAcc">
                      <h6>View breakdown</h6>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      <div className="bggrayBox p-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <h4 className="leftSideInvoiceHeading">
                              Consultation Fee
                            </h4>
                            <h4 className="leftSideInvoiceHeading">
                              Meri Sehat’s Commission
                            </h4>
                            <h4 className="leftSideInvoiceHeading">
                              Your Earnings
                            </h4>
                          </div>
                          <div>
                            <p className="rightSideInvoicePara">
                              Rs. {instantDiscountData?.consultation_fees}
                            </p>
                            <p className="rightSideInvoicePara">
                              {
                                instantDiscountData?.merisehat_commission_inpercent
                              }
                              % (Rs.
                              {instantDiscountData?.merisehat_commission_value})
                            </p>
                            <p className="rightSideInvoicePara">
                              {instantDiscountData?.your_earning_inpercent}%
                              (Rs.{instantDiscountData?.your_earning_value})
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </Accordion>
            </Modal.Body>
            <Modal.Footer className="border-top-0 row flex-md-nowrap justify-content-center pb-4">
              <Button
                className="btn-btn-lg btn-transparent modal-btn me-md-3"
                onClick={handlehide2}
              >
                No
              </Button>

              <Button
                className="btn btn-lg theme-bg modal-btn ms-md-3"
                onClick={hideModalAndInstantOpen}
              >
                Yes
              </Button>
            </Modal.Footer>
          </AntModal>
        </section>
      )}
      {openPop ? (
        <>
          <Row>
            <Modal className="dashboardTermsModal" show={true}>
              <Modal.Header className="border-bottom-0 text-center m-auto">
                <h4 className="fw-600 ff-Nunito">Terms & Conditions</h4>
              </Modal.Header>
              <Modal.Body className="pt-0 text-left px-5">
                <div className="mb-3">
                  <h5 className="fw-600 ff-Nunito text-uppercase introHeaad">
                    Introduction
                  </h5>
                  <p>
                    These terms and conditions apply to the doctor who avails
                    any of the services offered by Meri Sehat through any of its
                    platforms (web, app and/or call center). Kindly read these
                    terms and conditions carefully. Meri Sehat reserves all the
                    rights to amend these terms and conditions at any time
                    without giving prior notice. It is the responsibility of the
                    doctor to have read the terms and conditions before using
                    the service.
                  </p>
                </div>
                <div className="mb-3">
                  <h5 className="fw-600 ff-Nunito text-uppercase introHeaad">
                    Key Terms:
                  </h5>
                  <p>
                    The following is a summary of the key terms of this service:
                  </p>
                  <ul className="ps-3">
                    <li>
                      All the amount collected will be sent in bi-monthly
                      payment cycles via the bank details that you have
                      provided.
                    </li>
                    <li>
                      If the patient pays an X amount of fee (e.g: Rs:500), its
                      Y% will be charged as platform fee.
                    </li>
                    <li>
                      In the scheduled online video consultation and find a
                      doctor, the doctor can choose the fees amount themselves
                    </li>
                    <li>
                      The doctor agrees that Meri Sehat or any of its employees
                      will not be held liable by the doctor for any loss or
                      damages arising from your use of, or reliance upon the
                      information contained on the Website, or any failure to
                      comply with these Terms and Conditions where such failure
                      is due to circumstances beyond Meri Sehat’s reasonable
                      control.
                    </li>
                    <li>
                      The doctor is responsible to ensure that the card/ bank
                      account details provided by him/ her are accurate and with
                      the CNIC number provided.
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h5 className="fw-600 ff-Nunito text-uppercase introHeaad">
                    Cancellations of Prepaid Appointments:
                  </h5>
                  <ul className="ps-3">
                    <li>
                      For any online prepaid appointment, the doctor can cancel
                      his/her scheduled appointment without any charges till 1
                      hour before the appointment. In such cases, we will refund
                      the amount paid for appointment subject to bank charges
                      and other deductions by third parties.
                    </li>
                    <li>
                      Appointment cancellation has to be carried out by the
                      doctor through the Meri Sehat app. We do not entertain
                      cancellation requests through phone or email.
                    </li>
                    <li>
                      Occasionally, appointments may be canceled or postponed by
                      the Third Party Service Provider (Meri Sehat, etc.).
                      Should this occur, we will attempt to contact and inform
                      the doctor timely.
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h5 className="fw-600 ff-Nunito text-uppercase introHeaad">
                    Registration:
                  </h5>
                  <p>
                    As part of the registration process, you will provide an
                    e-mail address and create a password. These are your
                    credentials for accessing the services that are only
                    available to onboarded members (“Credentials”). You should
                    keep your Credentials private and not share your Credentials
                    with anyone else. You must immediately notify us if your
                    password has been stolen or compromised by sending an email
                    to cs@merisehat.pk.
                  </p>
                </div>
                <div className="mb-3">
                  <h5 className="fw-600 ff-Nunito text-uppercase introHeaad">
                    Your Personal Information:
                  </h5>
                  <p>
                    In order to register, you must provide certain basic
                    information about yourself. Our Privacy Policy details how
                    we may use, share and maintain your information, which may
                    include without limitation your name, address, NIC and
                    contact information; bank details; education; experience;
                    etc. By submitting such information, you authorize Meri
                    Sehat, its employees, agents and others operating on its
                    behalf to use and/or disclose such information in accordance
                    with our Privacy Policy. Please review the Privacy Policy
                    carefully, as your use of the Services constitutes your
                    agreement to it.
                  </p>
                </div>
                <div className="mb-3">
                  <h5 className="fw-600 ff-Nunito text-uppercase introHeaad">
                    Your Responsibilities:
                  </h5>
                  <ul className="ps-3">
                    <li>
                      <strong>(A).</strong> You will not use the Services to
                      view, access or otherwise use, directly or indirectly,
                      price, availability, or other Content for any purpose
                      other than your own personal use as a doctor or
                      prospective doctor.
                    </li>
                    <li>
                      <strong>(B).</strong> You will not use the Services to
                      establish, attempt to establish, or enforce, directly or
                      indirectly, any agreement or coordination of the prices
                      charged for any product or service; the kinds, frequencies
                      or amounts of any product or service offered; or the
                      patient or patient categories for any product or service,
                      or otherwise engage or attempt to engage in price fixing,
                      output restriction, or customer or market allocation.
                    </li>
                    <li>
                      <strong>(C).</strong> You will not use the Services,
                      directly or indirectly, to engage in any anti-competitive,
                      deceptive or unfair practices, or otherwise violate
                      applicable antitrust, competition or consumer protection
                      laws, or regulations.
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <p>
                    If you provide any information that is untrue, not current,
                    or incomplete, we have the right to suspend or terminate
                    your account and refuse any and all current or future use of
                    the Site (or any portion thereof).
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer className="border-top-0 pb-4 d-block text-center px-5">
                <Checkbox
                  onChange={handleChangeTerms}
                  className="d-flex checkBoxAgreee px-0"
                >
                  I agree to the Terms & Conditions of Meri Sehat
                </Checkbox>
                <button
                  disabled={!temrsCheckbox}
                  className="btn btn-lg theme-bg modal-btn ms-md-3"
                  onClick={() => setOpenPop(false)}
                >
                  Continue
                </button>
              </Modal.Footer>
            </Modal>
          </Row>
        </>
      ) : null}
    </>
  );
}

export default Dashboard;
